import Window from '../../components/Window';
import Footer from '../../components/Footer';
import { getDesktopIcons, desktopIcons } from "../../components/desktopIcon";


export default function Vlog({ track }, props) {

    return (

        <div>
            <div style={{ width: "100vw", height: "100vh", display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                <Window props={{
                    windowTitle: "Vlog",
                    style: {
                        width: "75vw",
                        height: "100%",
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflow: 'auto'
                    }
                }} >
                    {getDesktopIcons(desktopIcons)}

                    <div className="windows-container">
                        <ul className="windows-toolbar">
                            <li className="toolbar-item"> <p className="windows-link">Disc </p> </li>
                            <li className="toolbar-item"> <p className="windows-link">View </p></li>
                            <li className="toolbar-item"> <p className="windows-link">Option </p> </li>
                            <li className="toolbar-item"> <p className="windows-link">Help </p></li>
                        </ul>
                    </div>
                    <div className="youtube-container">
                        <iframe
                            src="https://www.youtube.com/embed/EOvtSz1yyHc"  // Example video link
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        ></iframe>
                    </div>
                </Window>
            </div>
            <Footer />

        </div>

    );

}