import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import closeIcon from '../static/imgs/windowsIcons/close.png';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    padding: 2,
    overflow: 'auto',
    justifyItems: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    maxHeight: '100vh',
    maxWidth: '100vw',

};

export default function ClothesModal({ props }) {
    const [open, setOpen] = React.useState(false);
    const [variantSelected, setVariantSelected] = React.useState(0);

    const handleOpen = () => {
        if (props.inStock) {
            setOpen(true)
        }
    }

    const handleClose = () => setOpen(false);

    return (
        <div>
            <Button onClick={handleOpen}>{props.openText}</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Fade in={open}>

                    <Box sx={style}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={handleClose}>
                                <img height="1px" src={closeIcon} alt="close" />
                            </Button>
                        </div>

                        <div style={{ textAlign: 'center' }}>

                            <Typography id="modal-modal-title" variant="h4" component="h4">
                                {props.title}
                            </Typography>
                            <Typography id="modal-modal-title" variant="p" component="p" style={{ fontWeight: 'bold' }}>
                                ${props.price}0
                            </Typography>


                        </div>

                        <div style={{ paddingTop: '10px', paddingBottom: '10px', alignContent: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                            <img alt='product' style={{
                                width: '100%', boxShadow: 'rgb(226, 226, 226) 0px 0px 0px 3px',
                            }} src={props.src}></img>
                        </div>


                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div>
                                {(props.variants && props.variants.edges.length > 1) &&
                                    <>
                                        Options:
                                        <select onChange={e => { setVariantSelected(e.target.value) }} className="product-options-selector">
                                            {props.variants.edges.map((node, index) => {
                                                const variant = node.node;
                                                return <option key={index} value={index} >{variant.title}</option>
                                            })}
                                        </select>

                                    </>}

                            </div>
                        </div>
                        <Button style={{ fontWeight: 'bold' }} onClick={() => { props.handleATC(props.variants?.edges?.[variantSelected].node.id); handleClose() }}>Add To Cart</Button>

                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
